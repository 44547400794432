import React from 'react'
import { v4 } from 'uuid'
import Img from 'gatsby-image'
import { DrawIcon } from '../../utils/icon'

import '../LandingPageContentSection/LandingPageContentSection.scss'

class LandingPageTextSection extends React.Component {
  render() {
    const { section } = this.props

    return (
      <div className="landing-page-text-section section is-small">
        {section.text_content.image && (
          <Img
            fixed={section.text_content.image.localFile.childImageSharp.fixed}
          />
        )}
        {section.title && <h3 className="section-header">{section.title}</h3>}
        {section.subtitle && (
          <p
            className="title-lead orange-underline"
            dangerouslySetInnerHTML={{
              __html: section.subtitle,
            }}
          />
        )}

        <div
          className="content"
          dangerouslySetInnerHTML={{
            __html: section.text_content.content,
          }}
        />
      </div>
    )
  }
}

export default LandingPageTextSection
