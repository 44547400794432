import React from 'react'
import { v4 } from 'uuid'
import YouTube from 'react-youtube-embed'

import '../LandingPageContentSection/LandingPageContentSection.scss'

class LandingPageVideo extends React.Component {
  render() {
    const { section } = this.props

    return (
      <div className="landing-page-video section is-small">
        <YouTube id={section.video_id} />
      </div>
    )
  }
}

export default LandingPageVideo
