import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/Hero/Hero'
import LandingPageColumns from '../components/LandingPageColumns/LandingPageColumns'
import LandingPageContentSection from '../components/LandingPageContentSection/LandingPageContentSection'
import LandingPageFlipBoxes from '../components/LandingPageFlipBoxes/LandingPageFlipBoxes'
import LandingPageIcons from '../components/LandingPageIcons/LandingPageIcons'
import LandingPageTextSection from '../components/LandingPageTextSection/LandingPageTextSection'
import LandingPageVideo from '../components/LandingPageVideo/LandingPageVideo'
import SEO from '../components/SEO'

const PageTop = ({ page }) => (
  <section className="section no-padding-bottom">
    <div className="container">
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <div className="has-text-centered">
            <h1 className="section-header">{page.title}</h1>
            <p className="title-lead orange-underline centered">
              {page.acf.subtitle}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export const BlogPostTemplate = ({ page }) => (
  <div className="landing-page-template">
    <PageTop page={page} />

    {page.acf.content_sections.map((section, index) => {
      if (section.section_type === 'columns') {
        return <LandingPageColumns key={`section-${index}`} section={section} />
      }
      if (
        section.section_type === 'content-left' ||
        section.section_type === 'content-right'
      ) {
        return (
          <LandingPageContentSection
            key={`section-${index}`}
            section={section}
          />
        )
      }
      if (section.section_type === 'flip-boxes') {
        return (
          <LandingPageFlipBoxes key={`section-${index}`} section={section} />
        )
      }
      if (section.section_type === 'icons') {
        return <LandingPageIcons key={`section-${index}`} section={section} />
      }
      if (section.section_type === 'text') {
        return (
          <LandingPageTextSection key={`section-${index}`} section={section} />
        )
      }
      if (section.section_type === 'video') {
        return <LandingPageVideo key={`section-${index}`} section={section} />
      }
    })}
  </div>
)

const BlogPost = ({ data }) => {
  const { wordpressPost: post } = data

  return (
    <Layout color="dark" slug={post.slug}>
      <SEO title={post.title} />
      <BlogPostTemplate page={post} />
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date(formatString: "MMMM DD, YYYY")
    title
  }
  query BlogPostByID($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      date(formatString: "MMMM DD, YYYY")
      categories {
        name
        slug
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 1035) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      acf {
        header_type
        subtitle
        content_sections {
          section_type
          title
          subtitle
          text_content {
            content
          }
          video_id
        }
      }
    }
  }
`
